// IMPORTS
import { useContext, useEffect, useState } from 'react';
import {
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from '@mui/material';
import { Prompt, useParams } from 'react-router-dom';
import moment from 'moment';
import { SiteContext } from '../../../main/Main';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Tabs from '../../global/Tabs';
import CustomerTable from '../../global/customerFormComponents/CustomerTable';
import SelectVehicleForm from './components/selectEntities/SelectVehicleForm';
import SelectCustomerForm from './components/selectEntities/SelectCustomerForm';
import SelectTechForm from './components/selectEntities/SelectTechForm';
import LogsDrawerContent from './components/LogsDrawerContent';
import Details from './components/Details';
import Charges from './components/Charges';
import SpeedDial from '../../global/SpeedDial';
import SpeedDialList from './components/SpeedDialList';
import CustomerHistoryContent from './components/CustomerHistoryContent';
import VehicleHistoryContent from './components/VehicleHistoryContent';
import TechTimeClocks from '../../header/components/TechTimeClocks';
import Drawer from '../../global/Drawer';
import TextMessageDrawerContent from '../../global/TextMessageDrawerContent';
import Paper from '../../global/Paper';
import PageDoesNotExist from '../../global/PageDoesNotExist';
// LOGIC
import GetServiceDetails from './logic/GetServiceDetails';
import { ToFixed } from '../../global/logic/Formatters';
import HandleDeleteService from './logic/HandleDeleteService';
import HandleUpdateService from './logic/HandleUpdateService';
import HandleSubmitService from './logic/HandleSubmitService';
import { ServiceTotal } from './logic/GetTotals';
// INTERFACES
import { Customer, Site, User } from '../../global/interfaces/GeneralInterface';
import { Vehicle } from '../../global/interfaces/VehicleSaleInterface';
import {
    CustomerVehicle,
    PurchaseOrder,
    Service
} from '../../global/interfaces/ServiceInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { Order } from '../../global/interfaces/PartsInterface';
import { withSnackbar } from '../../global/WrappingSnackbar';

const NewBooking = ({
    selectId = null,
    technicianId = null,
    startDate = String(moment().format('YYYY-MM-DD') + 'T09:00'),
    endDate = null,
    showSnackbar
}: {
    selectId: number;
    technicianId: number;
    startDate: string;
    endDate: string;
    showSnackbar: showSnackbar;
}) => {
    const siteDetails = useContext<Site>(SiteContext);
    const { id } = useParams<{ id: string }>();
    const idFromCalendar = selectId;
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

    const [onLoad, setOnLoad] = useState<boolean>(true);
    const [invalid, setInvalid] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [notesDialogOpen, setNotesDialogOpen] = useState<boolean>(false);
    const [textDrawerOpen, setTextDrawerOpen] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [customerHistoryDrawerOpen, setCustomerHistoryDrawerOpen] =
        useState<boolean>(false);
    const [vehicleHistoryDrawerOpen, setVehicleHistoryDrawerOpen] =
        useState<boolean>(false);
    const [timeClockOpen, setTimeClockOpen] = useState<boolean>(false);
    const [logsDrawerOpen, setLogsDrawerOpen] = useState<boolean>(false);

    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
    const [selectedInsurer, setSelectedInsurer] = useState<Customer>();
    const [vehicleType, setVehicleType] = useState<string>('customer');
    const [selectedVehicle, setSelectedVehicle] = useState<
        Vehicle | CustomerVehicle
    >();
    const [selectedTech, setSelectedTech] = useState<User>();
    const [preSelectTechId, setPreSelectedTechId] =
        useState<number>(technicianId);

    const [sublets, setSublets] = useState<PurchaseOrder[]>([]);
    const [partSales, setPartSales] = useState<Order[]>([]);
    const [serviceDetails, setServiceDetails] = useState<Service>({
        jobType: 'Retail',
        serviceComplete: 'Incomplete',
        environmentalLevy: '0',
        sundries: '0',
        labourCost: '0',
        subletTotal: '0',
        customerExcess: '0',
        totalPrice: '0',
        miscCosts: [],
        addedCosts: [],
        paymentLines: [],
        jobStart: startDate,
        jobFinish: endDate,
        jobCodes: []
    });

    useEffect(() => {
        if (id || idFromCalendar) {
            GetServiceDetails(
                idFromCalendar ? idFromCalendar : parseInt(id),
                setOnLoad,
                setServiceDetails,
                setSublets,
                setPartSales,
                setSelectedTech,
                setSelectedVehicle,
                setVehicleType,
                setSelectedInsurer,
                setSelectedCustomer,
                setReadOnly,
                setLoading,
                showSnackbar,
                setInvalid
            );
        }
        // eslint-disable-next-line
    }, [id, idFromCalendar]);

    useEffect(() => {
        if (!onLoad || !id) {
            updateServiceTotal();
        }
        // eslint-disable-next-line
    }, [
        partSales,
        serviceDetails.miscCosts,
        serviceDetails.addedCosts,
        serviceDetails.subletTotal,
        serviceDetails.environmentalLevy,
        serviceDetails.sundries
    ]);

    const updateServiceTotal = () => {
        let newServiceTotal = ToFixed(
            ServiceTotal(serviceDetails, sublets, partSales)
        );
        setServiceDetails({
            ...serviceDetails,
            totalPrice: String(newServiceTotal)
        });
    };

    const speedDialList = () => {
        return SpeedDialList(
            idFromCalendar ? idFromCalendar : parseInt(id),
            readOnly,
            setReadOnly,
            setLogsDrawerOpen,
            selectedTech,
            setTimeClockOpen,
            selectedVehicle,
            vehicleType,
            setVehicleHistoryDrawerOpen,
            selectedCustomer,
            setCustomerHistoryDrawerOpen,
            setDeleteDialogOpen,
            setNotesDialogOpen,
            setTextDrawerOpen,
            setShouldBlockNavigation,
            handleSubmitService
        );
    };

    const handleSubmitService = () => {
        if (id || idFromCalendar) {
            HandleUpdateService(
                selectedCustomer,
                selectedInsurer,
                vehicleType,
                selectedVehicle,
                selectedTech,
                serviceDetails,
                partSales,
                sublets,
                showSnackbar
            );
        } else {
            HandleSubmitService(
                selectedCustomer,
                selectedInsurer,
                vehicleType,
                selectedVehicle,
                selectedTech,
                serviceDetails,
                partSales,
                sublets,
                showSnackbar
            );
        }
    };

    let customerTab = {
        id: 0,
        title: 'Select Customer',
        content: (
            <>
                <SelectCustomerForm
                    readOnly={
                        readOnly || serviceDetails.paymentLines.length > 0
                    }
                    selectedCustomer={selectedCustomer}
                    setSelectedCustomer={setSelectedCustomer}
                    serviceDetails={serviceDetails}
                    setServiceDetails={setServiceDetails}
                    setVehicleType={setVehicleType}
                    setSelectedInsurer={setSelectedInsurer}
                    setSelectedVehicle={setSelectedVehicle}
                />
                {id || selectedCustomer ? (
                    <SpeedDial actionsList={speedDialList()} />
                ) : null}
            </>
        )
    };

    let insurerTab = {
        id: 1,
        title: 'Select Insurer',
        content: (
            <>
                <CustomerTable
                    readOnly={
                        readOnly || serviceDetails.paymentLines.length > 0
                    }
                    selectedEntity={selectedInsurer}
                    setSelectedEntity={setSelectedInsurer}
                    includeShippingDrawer={false}
                />
                {id || selectedInsurer ? (
                    <SpeedDial actionsList={speedDialList()} />
                ) : null}
            </>
        )
    };

    let vehicleTab = {
        id: serviceDetails.jobType === 'Insurance' ? 2 : 1,
        title:
            siteDetails.vehicleTypeSold === 'Motorcycles'
                ? 'Select Vehicle'
                : 'Select Bicycles',
        content: (
            <>
                <SelectVehicleForm
                    readOnly={
                        readOnly || serviceDetails.paymentLines.length > 0
                    }
                    vehicleType={vehicleType}
                    setVehicleType={setVehicleType}
                    selectedVehicle={selectedVehicle}
                    setSelectedVehicle={setSelectedVehicle}
                    selectedCustomer={selectedCustomer}
                    serviceDetails={serviceDetails}
                />
                {id || selectedVehicle ? (
                    <SpeedDial actionsList={speedDialList()} />
                ) : null}
            </>
        )
    };

    let techTab = {
        id: serviceDetails.jobType === 'Insurance' ? 3 : 2,
        title: 'Select Tech',
        content: (
            <>
                <SelectTechForm
                    readOnly={readOnly}
                    preSelectedTechId={preSelectTechId}
                    setPreSelectedTechId={setPreSelectedTechId}
                    selectedTech={selectedTech}
                    setSelectedTech={setSelectedTech}
                />
                <SpeedDial actionsList={speedDialList()} />
            </>
        )
    };

    let detailsTab = {
        id: serviceDetails.jobType === 'Insurance' ? 4 : 3,
        title: 'Details',
        content: (
            <>
                <Details
                    id={idFromCalendar ? idFromCalendar : parseInt(id)}
                    readOnly={readOnly}
                    serviceDetails={serviceDetails}
                    setServiceDetails={setServiceDetails}
                    sublets={sublets}
                    partSales={partSales}
                    selectedCustomer={selectedCustomer}
                    selectedInsurer={selectedInsurer}
                    selectedVehicle={selectedVehicle}
                    vehicleType={vehicleType}
                />
                <SpeedDial actionsList={speedDialList()} />
            </>
        )
    };

    let costsTab = {
        id: serviceDetails.jobType === 'Insurance' ? 5 : 4,
        title: 'Charges',
        content: (
            <>
                <Charges
                    id={idFromCalendar ? idFromCalendar : parseInt(id)}
                    readOnly={readOnly}
                    serviceDetails={serviceDetails}
                    setServiceDetails={setServiceDetails}
                    sublets={sublets}
                    setSublets={setSublets}
                    partSales={partSales}
                    setPartSales={setPartSales}
                    selectedCustomer={selectedCustomer}
                    vehicleType={vehicleType}
                    selectedVehicle={selectedVehicle}
                    selectedInsurer={selectedInsurer}
                    showSnackbar={showSnackbar}
                />
                <SpeedDial actionsList={speedDialList()} />
            </>
        )
    };

    let tabContent = [];

    if (serviceDetails.jobType === 'Insurance') {
        tabContent = [
            customerTab,
            insurerTab,
            vehicleTab,
            techTab,
            detailsTab,
            costsTab
        ];
    } else {
        tabContent = [customerTab, vehicleTab, techTab, detailsTab, costsTab];
    }

    return (
        <PageWrapper notVisible={idFromCalendar ? true : false}>
            {loading ? (
                <Paper>
                    <Typography align="center">
                        <CircularProgress />
                    </Typography>
                </Paper>
            ) : invalid ? (
                <PageDoesNotExist />
            ) : (
                <>
                    <Typography variant="h4">
                        {idFromCalendar
                            ? `Booking #${idFromCalendar}`
                            : id
                              ? `Booking #${id}`
                              : 'New Booking'}
                    </Typography>
                    <br />
                    <Tabs tabContent={tabContent} />
                </>
            )}

            {/* Service notes dialog */}
            {notesDialogOpen && (
                <Dialog
                    open={notesDialogOpen}
                    onClose={() => setNotesDialogOpen(false)}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle>Internal Notes</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            disabled={readOnly}
                            multiline
                            rows={6}
                            value={serviceDetails.notes}
                            onChange={(e) =>
                                setServiceDetails({
                                    ...serviceDetails,
                                    notes: e.target.value
                                })
                            }
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setNotesDialogOpen(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {/* Delete service dialog */}
            {deleteDialogOpen && (
                <Dialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>Delete Booking?</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            Are you sure you would like to delete booking #{id}
                            ?In doing so you will lose all data associated
                            permanently.
                        </Typography>
                        <br />
                        <Typography variant="body1" textAlign="center">
                            <b>This cannot be undone.</b>
                        </Typography>
                        <br />
                        {partSales.length > 0 && (
                            <>
                                <Typography variant="body1">
                                    <b>
                                        Warning: This booking has parts sales
                                        associated with it. Deleting this
                                        booking will also delete all associated
                                        parts sales:
                                    </b>
                                    {partSales.map((sale) => (
                                        <>
                                            <Chip
                                                sx={{ margin: '5px' }}
                                                variant="outlined"
                                                label={
                                                    (sale.status === 'Sale'
                                                        ? 'Sale'
                                                        : sale.status ===
                                                            'Proforma'
                                                          ? 'Proforma'
                                                          : 'Quote') +
                                                    ' ' +
                                                    sale.id
                                                }
                                                color={
                                                    sale.status === 'Sale'
                                                        ? 'success'
                                                        : sale.status ===
                                                            'Proforma'
                                                          ? 'warning'
                                                          : 'error'
                                                }
                                                onClick={() =>
                                                    window.open(
                                                        `/inventory/viewSale/${sale.id}`,
                                                        '_blank'
                                                    )
                                                }
                                            />
                                        </>
                                    ))}
                                </Typography>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDeleteDialogOpen(false)}>
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() =>
                                HandleDeleteService(
                                    idFromCalendar
                                        ? idFromCalendar
                                        : parseInt(id),
                                    serviceDetails.paymentLines,
                                    showSnackbar
                                )
                            }
                        >
                            Delete Booking
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {/* Text message drawer */}
            {textDrawerOpen && (
                <Drawer
                    openDrawer={textDrawerOpen}
                    setOpenDrawer={setTextDrawerOpen}
                    title="New Text Message"
                    subTitle={
                        selectedCustomer?.firstName +
                        ' ' +
                        selectedCustomer?.lastName
                    }
                    // ensure drawer is displayed in front the NewBooking
                    zIndex={1300}
                >
                    <TextMessageDrawerContent
                        id={idFromCalendar ? idFromCalendar : parseInt(id)}
                        department="Service"
                        customerName={
                            selectedCustomer?.companyName
                                ? selectedCustomer?.companyName
                                : selectedCustomer?.firstName +
                                  ' ' +
                                  selectedCustomer?.lastName
                        }
                        customerPhone={selectedCustomer?.phoneNumber}
                        serviceStartingDateTime={serviceDetails?.jobStart}
                        showSnackbar={showSnackbar}
                        proformaNumber={id}
                    />
                </Drawer>
            )}

            {/* Customer history drawer */}
            {customerHistoryDrawerOpen && (
                <Drawer
                    openDrawer={customerHistoryDrawerOpen}
                    setOpenDrawer={setCustomerHistoryDrawerOpen}
                    title="Customer Service History"
                    subTitle={
                        selectedCustomer?.firstName +
                        ' ' +
                        selectedCustomer?.lastName
                    }
                    // ensure drawer is displayed in front the NewBooking
                    zIndex={1300}
                >
                    <CustomerHistoryContent
                        CustomerId={selectedCustomer?.id}
                        ServiceId={
                            idFromCalendar ? idFromCalendar : parseInt(id)
                        }
                    />
                </Drawer>
            )}

            {/* Vehicle history drawer */}
            {vehicleHistoryDrawerOpen && (
                <Drawer
                    openDrawer={vehicleHistoryDrawerOpen}
                    setOpenDrawer={setVehicleHistoryDrawerOpen}
                    title="Vehicle Service History"
                    subTitle={
                        selectedVehicle?.year +
                        ' ' +
                        selectedVehicle?.Make?.name +
                        ' ' +
                        selectedVehicle?.Model?.name +
                        ' ' +
                        selectedVehicle?.Series?.name
                    }
                    // ensure drawer is displayed in front the NewBooking
                    zIndex={1300}
                >
                    <VehicleHistoryContent
                        VehicleId={selectedVehicle?.id}
                        ServiceId={
                            idFromCalendar ? idFromCalendar : parseInt(id)
                        }
                    />
                </Drawer>
            )}

            {/* Tech time clock dialog */}
            {timeClockOpen && (
                <Dialog
                    open={timeClockOpen}
                    onClose={() => setTimeClockOpen(false)}
                    maxWidth="lg"
                    fullWidth
                >
                    <TechTimeClocks
                        ServiceId={
                            idFromCalendar ? idFromCalendar : parseInt(id)
                        }
                        service={serviceDetails}
                        vehicle={selectedVehicle}
                        selectedTech={selectedTech}
                    />
                </Dialog>
            )}

            {/* Logs drawer */}
            {logsDrawerOpen && (
                <Drawer
                    openDrawer={logsDrawerOpen}
                    setOpenDrawer={setLogsDrawerOpen}
                    title="Change History"
                    width="40vw"
                    subTitle=""
                    // ensure drawer is displayed in front the NewBooking
                    zIndex={1300}
                >
                    <LogsDrawerContent
                        id={idFromCalendar ? idFromCalendar : parseInt(id)}
                    />
                </Drawer>
            )}

            {/* Prompt to block navigation */}
            <Prompt
                when={shouldBlockNavigation}
                message="You have unsaved changes, are you sure you want to leave?"
            />
        </PageWrapper>
    );
};

export default withSnackbar(NewBooking);
